import '~/assets/dev/styles.scss';

jQuery(document).ready(function($) {

	// Focus styles for menus when using keyboard navigation
	// Properly update the ARIA states on focus (keyboard) and mouse over events
	$( '[role="menubar"]' ).on( 'focus.aria mouseenter.aria', '[aria-haspopup="true"]', function ( ev ) {
		$( ev.currentTarget ).attr( 'aria-expanded', true );
	});
	// Properly update the ARIA states on blur (keyboard) and mouse out events
	$( '[role="menubar"]' ).on( 'blur.aria mouseleave.aria', '[aria-haspopup="true"]', function ( ev ) {
		$( ev.currentTarget ).attr( 'aria-expanded', false );
	});


	let checkFirstFcl = function() {
	    let firstFclElement = $('#site-content > .page-wrapper > .fcl:first-of-type');
	    let bodyElement = $('body');
	    let singleContentHero = $('.single-content-hero');

	    if (firstFclElement.hasClass('fcl-hero') && !bodyElement.hasClass('single')) {
	        bodyElement.addClass('fe-is-hero');
	    } else {
	        bodyElement.addClass('fe-is-not-hero');
	        let mastheadOffset = $('#masthead').height();
	        $('#site-content').css('margin-top', mastheadOffset);
	        singleContentHero.css('margin-top', mastheadOffset);
	    }
	}
	checkFirstFcl();


	let closeOutMobileNav = function() {
		$('.mobile-nav-toggle').removeClass('is-active');
	  	$('#mobile-nav-wrapper').removeClass('is-active');
	    $('body').removeClass('mobile-nav-active');
	}

	// init mobile nav toggle
	let initMobileNavToggle = function() {
	    var mobileMenuWrapper = $('#mobile-nav-wrapper');
	    $('.mobile-nav-toggle').on('click', function() {
	        if($(this).hasClass('is-active')) {
	            closeOutMobileNav();
	        } else {
	            $(this).addClass('is-active');
	            mobileMenuWrapper.addClass('is-active');
	            $('body').addClass('mobile-nav-active');
	            let mobileNavOffset;
	            if($('#sticky-masthead').hasClass('is-active')) {
	                mobileNavOffset = $('#sticky-masthead').height() + 15;
	            } else {
	                mobileNavOffset = $('#masthead').height() + 15;
	            }
	            mobileMenuWrapper.css('top', mobileNavOffset);
	        }
	    });
	    $('#mobile-nav-wrapper a').on('click', function() {
	    	closeOutMobileNav();
	    });
	}
	initMobileNavToggle();


	// testimonial slider
	let testimonialSliderInit = function() {
		$('.fcl-testimonial-slider').each(function() {
			let slider = $(this).find('.slick-slider');
			if(slider.length > 0) {
				slider.slick({
					centerMode: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 7500,
					responsive: [
						{
							breakpoint: 991,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 769,
							settings: {
								slidesToShow: 1
							}
						}
					]
				});
			}
		});
	}
	testimonialSliderInit();

	// sticky masthead
	var lastScrollTop = 0;

    $(window).scroll(function(event) {
        var st = $(this).scrollTop();
        
        if (st < lastScrollTop && st > 200) {
            // User is scrolling up and is at least 200px from the top
            $('#sticky-masthead').addClass('is-active');
        } else {
            // User is either scrolling down or is less than 200px from the top
            $('#sticky-masthead').removeClass('is-active');
        }
        
        lastScrollTop = st;
    });


	$(window).on('load', function() {
	
	});

	var resizeTimer;

	$(window).on('resize', function(e) {

	  clearTimeout(resizeTimer);
	  resizeTimer = setTimeout(function() {

	  	if($(window).width() > 1200) {
	  		closeOutMobileNav();
	  	}
	            
	  }, 50);

	});




});